
























































































import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { GeneralCommentsObjectContent } from "@/types/interfaces";
import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import GeneralCommentsModule from "@/store/vuex-modules/GeneralComments";

const commentsStore = getModule(GeneralCommentsModule);

@Component({})
export default class GeneralCommentComponent extends Vue {
    @Prop({ default: "" })
    commentId!: string;

    @Prop({ default: "" })
    accessor!: string;

    @Prop({ default: "Comments" })
    heading!: string;

    commentsInterval = -1;
    messageToComment = "";
    comments: Array<GeneralCommentsObjectContent> = [];
    commentIsPosting = false;
    counter = 0;

    objParams = {
        id: this.commentId,
        type: this.accessor,
        loadFrom: ""
    };

    /**
     * Computed
     */
    get userId() {
        return this.$store.getters.user;
    }

    get isoLastCommentLoaded() {
        const comments = this.comments;
        let returnValue = "";
        let lastItem;

        if (Array.isArray(comments)) {
            lastItem = comments[comments.length - 1];
        }

        if (lastItem && lastItem?.createdTime) {
            returnValue = lastItem.createdTime;
        }

        return returnValue;
    }

    /**
     * Lifecycle
     */
    created() {
        this.loadComments();
    }

    beforeDestroy() {
        this.clearCommentsInterval();
    }

    /**
     * Methods
     */
    isOwnComment(comment: GeneralCommentsObjectContent) {
        return Boolean(this.userId.id === comment.attendeeId);
    }

    clearCommentsInterval() {
        window.clearInterval(this.commentsInterval);
    }

    loadComments() {
        this.clearCommentsInterval();

        this.objParams.loadFrom = this.isoLastCommentLoaded;

        const params = this.objParams;
        commentsStore
            .getComments(params)
            .then((res) => {
                const x = res as Array<GeneralCommentsObjectContent>;
                const newComments = [...this.comments, ...x];

                this.comments = uniqWith(newComments, isEqual);
                this.commentsInterval = window.setInterval(
                    this.loadComments,
                    5000
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }

    addComment() {
        if (this.commentIsPosting) return;

        const commentObj = {
            relatedObjectId: this.commentId,
            relatedObjectType: this.accessor,
            attendeeId: this.userId.id,
            attendeeName: this.userId.name,
            text: this.messageToComment
        };

        this.clearCommentsInterval();

        this.commentIsPosting = true;
        commentsStore
            .postComment(commentObj)
            .then(() => {
                this.loadComments();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.commentIsPosting = false;
                this.messageToComment = "";

                // Only want to scrollchat after submitting a comment
                this.scrollChat();
            });
    }

    scrollChat() {
        this.$nextTick(() => {
            const chatScroll: any = this.$refs["chat-scroll"];
            // scroll to bottom of chat
            if (chatScroll) {
                chatScroll.scrollTo(null, chatScroll.scrollHeight);
            }
        });
    }
}
